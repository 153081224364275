/* eslint-disable */
import React from "react"
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Obfuscate from "react-obfuscate"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Content, { HTMLContent } from "../../components/Content"
import LogoClients from "../../components/logoClients"
import LogoPartenaires from "../../components/logoPartenaires"

const SocietePage = ({ data }) => {
  const { edges } = data.allMarkdownRemark
  const PostContent = HTMLContent || Content
  return (
    <Layout langKey={"fr"}>
      <SEO
        title="La société Aprogsys - Votre prestataire de services informatiques en Franche-Comté - Editeur de logiciel"
        description="Aprogsys est une ESN (ou SSII) située à Besançon dans le Doubs"
        keywords={[`Ingénierie Informatique`, `SSII`, `ESN`, `Besançon`]}
        jsonLd={[
          {
            "@context": "https://schema.org",
            "@id": "https://www.aprogsys.com/#organization",
            "@type": "Corporation",
            name: "Aprogsys",
            legalName: "SAS Aprogsys",
            url: "https://www.aprogsys.com",
            logo: "https://www.aprogsys.com/aprogsys.svg",
            description:
              "Prestataire de services en ingénierie informatique à Besançon - Editeur de logiciels",
            address: [
              {
                "@type": "PostalAddress",
                addressLocality: "Besançon",
                postalCode: "25000",
                streetAddress: '14 rue la Fayette - Bâtiment B'
              },
            ],
            foundingDate: "2001",
            founders: [
              {
                "@type": "Person",
                "name": "Vincent Etienne"
              },
              {
                "@type": "Person",
                "name": "Vincent Beurey"
              },
              {
                "@type": "Person",
                "name": "Cyril Maréchal"
              }
            ],
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+33381477038",
                email: "aprogsys@aprogsys.com",
                contactType: "customer service"
              },
              {
                "@type": "ContactPoint",
                telephone: "+33381477036",
                email: "bko@aprogsys.com",
                contactType: "technical support",
                areaServed : "FR",
                availableLanguage : ["English", "French"]
              },
            ],
            sameAs: [
              "https://fr-fr.facebook.com/Aprogsys-145799498817052/",
              "https://fr.linkedin.com/company/aprogsys",
              "https://fr.viadeo.com/fr/company/aprogsys",
              "https://www.pagesjaunes.fr/pros/08623341",
              "https://twitter.com/aprogsys",
              "https://www.societe.com/societe/aprogsys-437768385.html",
              "https://lafayette-entreprises.com/adherents/aprogsys/"
            ],
            owns: [
              {
                "@type": "Product",
                name: "Beetracking",
                url: "https://www.beetracking.com"
              },
              {
                "@type": "Product",
                name: "Caves-Explorer",
                url: "https://www.caves-explorer.com"
              }
            ]
          },
          {
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "L'entreprise Aprogsys",
                item: "https://www.aprogsys.com/fr/societe/",
              },
            ],
          },
        ]}
      />
      <section>
        <div className="hero societe-banner">
          <div className="hero-body container">
            <div className="hero-title">
              <div className="columns is-centered">
                <div className="column is-6">
                  <div className="societe-banner-content">
                    <h1 className="is-size-3-touch"><span>La société Aprogsys</span></h1>
                    <div className="subtitle is-size-4-touch"><span>15 années de services et d'innovations en <strong>ingénierie informatique</strong></span></div>
                  </div>
                </div>
                <div className="column is-4 schema-aprogsys">
                  <div className="is-size-4 is-hidden-touch" style={{position:'absolute',right: 60}}>
                    <p className="has-text-white" style={{position:'absolute',top:'170px', left: '-58px'}}>Application</p>
                    <p className="has-text-white" style={{position:'absolute',top:'30px', left:'130px'}}>Programmation</p>
                    <p className="has-text-white" style={{position:'absolute',top:'198px', left:'230px'}}>Systèmes</p>
                    <svg style={{width: 300, height: 300, marginTop: -20}}>
                      <use xlinkHref="/img/societe-sprite.svg#icon-aprogsys-white" />
                    </svg>
                  </div>
                  <div className="is-size-5 is-hidden-desktop" style={{position:'relative', width:300, margin: '0 auto'}}>
                    <p className="has-text-white" style={{position:'absolute',top:'190px', left: '5px'}}>Application</p>
                    <p className="has-text-white" style={{position:'absolute',top:'60px', left:'130px'}}>Programmation</p>
                    <p className="has-text-white" style={{position:'absolute',top:'218px', left:'210px'}}>Systèmes</p>
                    <svg style={{width: 300, height: 300}}>
                      <use xlinkHref="/img/societe-sprite.svg#icon-aprogsys-white" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container societe-description">
          <div className="columns is-multiline">
            <div className="column is-8-desktop is-full-touch">
              <h2 className="is-size-4-touch has-text-centered-touch">{edges[0].node.frontmatter.title}</h2> 
              <PostContent content={edges[0].node.html} className={"content"} />
            </div>
            <div className="column is-4-desktop is-full-touch has-text-centered">
              <svg className="is-hidden-touch" style={{width: 300, height: 300}}>
                <use xlinkHref="/img/societe-sprite.svg#icon-carte-france" />
              </svg>
              <svg className="is-hidden-desktop" style={{width: 200, height: 200}}>
                <use xlinkHref="/img/societe-sprite.svg#icon-carte-france" />
              </svg>
              <div style={{marginLeft:'30%'}}>
                <p className="has-text-left">14 rue la Fayette Bâtiment B<br/>25000 - Besançon</p>
                <p className="has-text-left" style={{ position: 'relative' }}>
                  <svg style={{ height: 25, width: 25, position: 'relative', top: 5, marginRight: '0.5rem' }}>
                    <use xlinkHref="/img/societe-sprite.svg#icon-tel" />
                  </svg>
                  +33 (0)3 81 47 70 38
                </p>
                <p className="has-text-left" style={{ position: 'relative' }}>
                  <svg style={{ height: 28, width: 28, position: 'relative', top: 5, marginRight: '0.5rem' }}>
                    <use xlinkHref="/img/societe-sprite.svg#icon-mail" />
                  </svg>
                  <Obfuscate email="aprogsys@aprogsys.com">aprogsys@aprogsys.com</Obfuscate>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="societe-direction">
        <div className="container">
          <h2 className="is-size-4-touch">Direction</h2>
          <div className="columns is-multiline is-centered">
            {edges[0].node.frontmatter.direction.map((personne, key) => (
              <div className="column is-4" key={key}>
                <div className="media">
                  <figure className="media-left is-hidden-touch">
                    <svg className="perso" style={{width:150}}>
                      <use xlinkHref="/img/societe-sprite.svg#icon-manager" />
                    </svg>
                  </figure>
                  <div className="media-content">
                    <div className="content">
                      <p><strong>{personne.prenom}<br/>{personne.nom}</strong></p>
                      <p>{personne.poste}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="clients" className="section">
        <div className="container">
          <h2 className="is-size-4-touch">Ils nous font confiance</h2>
          <LogoClients />
        </div>
      </section>
      {/*<section id="partenaires" className="section">
        <div className="container">
          <h2 className="is-size-4-touch">Partenaires :</h2>
          <LogoPartenaires />
        </div>
      </section> */}
      <section id="recrutement" className="societe-recrutement">
        <div className="container">
          <h2 className="is-size-4-touch">Recrutement</h2>
          <div className="content has-text-justified">
            <p>Rejoignez Aprogsys, intégrez une équipe jeune et dynamique et révélez vos talents !</p>
          </div>
          <div className="columns is-vcentered">
            <div className="column is-6">
              <div className="content has-text-justified">
                <div>Envoyez-nous votre candidature :</div>
                <div><span className="has-text-weight-bold">Par e-mail :</span> <Obfuscate email="aprogsys@aprogsys.com" className="has-text-primary">aprogsys@aprogsys.com</Obfuscate></div>
              </div>
            </div>
            <div className="column is-6 is-hidden-touch">
              <svg style={{height:200}}>
                <use xlinkHref="/img/societe-sprite.svg#icon-recrute" />
              </svg>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

SocietePage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default SocietePage

export const SocietePageQuery = graphql`
query SocieteFrQuery {
  allMarkdownRemark(
    filter: { frontmatter: { templateKey: { eq: "societe" }, langKey: { eq: "fr" } } }
    sort: { fields: [frontmatter___order], order: ASC }
  ) {
    totalCount
    edges {
      node {
        id
        html
        frontmatter {
          title
          direction {
            nom,
            prenom,
            poste
          }
        }
      }
    }
  }
}`